$(document).on('click', "#onetime-payment-button", onOnetimePaymentButtonClick);

function onOnetimePaymentButtonClick() {
	var stripe_publishable_key = 'pk_test_51IWjMZEHxG8uZVaTD0AVnUwXOcgLEk8HNiQnnbmW8hNHLYKgRacuWUa3LUNnVtWq0hod6slRiqNvw9WhAP2hEywS00hKwe2RvI'
	var stripe = Stripe(stripe_publishable_key);
	var job_id_to_pay = $("#job_id_to_pay").val();
	
	fetch("/charges", 
	{
    method: "POST",
		headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      job_id_to_pay: job_id_to_pay
    })
  }).then((response) => response.json())
    .then((data) => {
				stripe
					.redirectToCheckout({
						sessionId: data.sessionId
					})
					.then(function(result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function(error) {
          console.error('Error:', error);
        });
			});
}
