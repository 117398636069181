import consumer from "./consumer"

const personalMessageChannel = consumer.subscriptions.create("PersonalMessageChannel", {
  connected() {
		console.log("Connected to the personal message channel!");
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.message_id && $(`#conversation-user-${data.another_user_id}`).length) {
			$('#message-area').append(data.message);
			this.scroll_bottom();
			if (data.another_user_id != data.receiver_id) {
			  this.decrement_message_number(data.conversation_id, data.receiver_id);
			}
			else {
			  $("#personal_message_body").val("");
			}
		}
  },
	
	scroll_bottom() {
		$('#message-area').scrollTop($('#message-area')[0].scrollHeight);
	},
	
	decrement_message_number(conversation_id, receiver_id) {
		this.perform('decrement_message_number', {conversation_id, receiver_id})	
	}
	
});

export default personalMessageChannel;
