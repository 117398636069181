$(document).on('change', "#talent_filter_name", onTalentFilterNameChange) 
					 .on('click', "#talent-filter-restore-button", onTalentFilterRestoreButtonClick)
					 .on('click', "#talent-filter-save-button", onTalentFilterSaveButtonClick)
					 .on('click', "#talent-save-as-filter-button", onTalentFilterSaveAsButtonClick)
					 .on('click', "#talent-filter-delete-button", onTalentFilterDeleteButtonClick);

function onTalentFilterNameChange() {
		var filter_id = $(this).val();
		$.post('/talent_filters/select_filter', {
				filter_id: filter_id
		}, null, 'script');
}

function onTalentFilterRestoreButtonClick() {
		var filter_id = $("#talent_filter_name").val();
/* 	$.post('/talent_filters/click_restore', { */
		$.post('/talent_filters/select_filter', {			
			 filter_id: filter_id
		}, null, 'script');				
}

function onTalentFilterSaveButtonClick() {
		var filter_id = $("#talent_filter_name").val();
		var filter_params = getTalentFilterValues();
		$.post('/talent_filters/click_save', {
			 filter_id: filter_id,
			 filter_params: filter_params
		}, null, 'script');				
}

function onTalentFilterSaveAsButtonClick() {
	  var filter_params = getTalentFilterValues();
		$.post('/talent_filters/popup_save_filter', {
			filter_params: filter_params
		}, null, 'script');			
}

function onTalentFilterDeleteButtonClick() {
	  var filter_name = $("#talent_filter_name").find('option:selected').text();
		
		if(confirm("Are you sure to delete filter '" + filter_name + "' ?")){
				var filter_id = $("#talent_filter_name").val();
				$.post('/talent_filters/click_delete', {
					 id: filter_id
				}, null, 'script');	
        return false;
     } 
		 else {
        //if click no.
        return false;
     }
}

function getTalentFilterValues() {
	var filter_params = {
		filter_name: $("#talent_filter_name").find('option:selected').text(),
	  title: $("#talent_title").val(),
		skill_keywords: $("#talent_skill_keywords").val(),
		language: $("#talent_language").val(),
	  city: $("#talent_city").val(),
	  province: $("#talent_province").val(),
	  country: $("#talent_country").val(),
	  degree: $("#talent_degree").val(),
	  field_of_study: $("#talent_field_of_study").val(),
	  updated_days: $("#talent_updated_days").val()
	}
	
  return filter_params;
}






