$(document).on('change', "#candidate_talent_id", onCandidateTalentIdChange_1)
           .on('change', "#candidate1_select_job_id", onCandidateJobIdChange_1)
					 .on('change', "#candidate1_select_partnership_id", onCandidatePartnershipIdChange_1);

function onCandidateTalentIdChange_1() {
		var talent_id = $(this).val();
		var partnership_id = $("#candidate_partnership_id").val();
		window.location = '/candidates/new?partnership_id=' + partnership_id + '&talent_id=' + talent_id;
}

function onCandidateJobIdChange_1() {
	   console.log("candidate.js");
	  var job_folder = $("#job_folder").val();
		var select_job_id = $(this).val();
		window.location = '/candidates?job_folder=' + job_folder + '&select_job_id=' + select_job_id;
}

function onCandidatePartnershipIdChange_1() {
	  console.log("candidate.js");
	  var job_folder = $("#job_folder").val();
		var select_job_id = $("#select_job_id").val();
		var select_partnership_id = $(this).val();
		window.location = '/candidates?job_folder=' + job_folder + '&select_job_id=' + select_job_id + '&select_partnership_id=' + select_partnership_id;
}