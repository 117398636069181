$(document).on('click', "#subscription_checkout", onSubscribeButtonClick);

function onSubscribeButtonClick() {
	var stripe_publishable_key = 'pk_test_51IWjMZEHxG8uZVaTD0AVnUwXOcgLEk8HNiQnnbmW8hNHLYKgRacuWUa3LUNnVtWq0hod6slRiqNvw9WhAP2hEywS00hKwe2RvI'
	var stripe = Stripe(stripe_publishable_key);
	
	var priceId = "price_1IYZP7EHxG8uZVaTERnDDQKm"
	
	fetch("/create_checkout_session", 
	{
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      priceId: priceId
    })
  }).then((response) => response.json())
    .then((data) => {
				stripe
					.redirectToCheckout({
						sessionId: data.sessionId
					})
					.then(handleResult);
			});
}
