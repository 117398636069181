$(document).on('change', "#partnership1_job_id", onPartnershipJobIdChange_1)
					 .on('change', "#partnership2_job_id", onPartnershipJobIdChange_2)
					 .on('change', "#partnership3_select_id", onPartnershipJobIdChange_3);

function onPartnershipJobIdChange_1() {
		var job_id = $(this).val();
		var folder = $("#folder").val();
		window.location = '/partnerships?folder=' + folder + '&job_id=' + job_id;
}

function onPartnershipJobIdChange_2() {
		var job_id = $(this).val();
		var folder = $("#folder").val();
		window.location = '/my_partners?folder=' + folder + '&job_id=' + job_id;
}

function onPartnershipJobIdChange_3() {
		var select_id = $(this).val();
		var folder = $("#folder").val();
		window.location = '/partnered_jobs?folder=' + folder + '&select_id=' + select_id;
}