// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
require("jquery-ui")

require("packs/user")
require("packs/job")
require("packs/talent")
require("packs/partnership")
require("packs/candidate")
require("packs/subscribe_script")
require("packs/onetime_payment_script")
require("packs/static_page")

import "bootstrap";

import select2 from 'select2';
import 'select2/dist/css/select2.css';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import personalMessageChannel from "../channels/personal_message_channel";

$(document).on('turbolinks:load', function () {
  console.log("Hello, ActionCable");

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

if (document.title == "Search Jobs | QQrecruiter") {
	  // console.log("document.title = Search Jobs.");
		if (readCookie('search_jobs_page') == $(".pagination .active").text()) {
			// console.log("same page.");
			window.scrollTo(0,readCookie('search_jobs_ypos'));
		}
		else {
			// console.log("new page.");
			document.cookie='search_jobs_ypos=' + 0;
			window.scrollTo(0,0);		
		}
}
else {
		// console.log("document.title != Search Talents.");
		document.cookie = "search_jobs_ypos=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
		document.cookie = "search_jobs_page=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
}


if (document.title == "Search Talents | QQrecruiter") {
	  // console.log("document.title = Search Talents.");
		if (readCookie('search_talents_page') == $(".pagination .active").text()) {
			// console.log("same page.");
			window.scrollTo(0,readCookie('search_talents_ypos'));
		}
		else {
			// console.log("new page.");
			document.cookie='search_talents_ypos=' + 0;
			window.scrollTo(0,0);		
		}
}
else {
		// console.log("document.title != Search Talents.");
		document.cookie = "search_talents_ypos=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
		document.cookie = "search_talents_page=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
}

if (document.title == "Partnered Jobs | QQrecruiter") {
	  // console.log("document.title = Partnered Jobs.")
		window.scrollTo(0,readCookie('partnered_jobs_ypos'));
	}
else {
		// console.log("document.title != Partnered Jobs.")
		document.cookie = "partnered_jobs_ypos=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
}

function readCookie(name){
	return(document.cookie.match('(^|; )'+name+'=([^;]*)')||0)[2]
}

$("#user1_specialized_in").select2({
  multiple: true,
	maximumSelectionLength: 3
});

$("#user1_language").select2({
  multiple: true
});

$("#talent1_language").select2({
  multiple: true
});

$("#candidate1_language").select2({
  multiple: true
});

$("#partnership2_job_id").select2({
  multiple: false
});

$("#partnership3_select_id").select2({
  multiple: false
});

$("#candidate1_select_job_id").select2({
  multiple: false
});

$("#candidate1_select_partnership_id").select2({
  multiple: false
});

/* 	$("#user_name").autocomplete({
		source: '/users/name_autosuggest'        			
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
			   //console.log("This is application.js/user_name.autocomplete/_renderItem function.");
				 return $( "<li>" )
				.attr( "data-value", item.value )
				.append(item.label)
				.appendTo( ul );
			}; */

  $("#user_full_name").autocomplete({
		source: '/users/name_autosuggest'        			
    });	
	
  $("#user_city").autocomplete({
		source: '/users/city_autosuggest'        			
    });
		
  $("#user_province").autocomplete({
		source: '/users/province_autosuggest'        			
    });

  $("#user_company_name").autocomplete({
		source: '/users/company_autosuggest'        			
    });
		
/* 	$("#job_title").autocomplete({
		source: '/jobs/title_autosuggest'        			
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
			   //console.log("This is application.js/job_title.autocomplete/_renderItem function.");
				 return $( "<li>" )
				.attr( "data-value", item.value )
				.append(item.label)
				.appendTo( ul );
			}; */
  $("#job_title").autocomplete({
		source: '/jobs/title_autosuggest'        			
    });
		
  $("#job_city").autocomplete({
		source: '/jobs/city_autosuggest'        			
    });
		
  $("#job_province").autocomplete({
		source: '/jobs/province_autosuggest'        			
    });

  $("#job_company_name").autocomplete({
		source: '/jobs/company_autosuggest'        			
    });

  if($("#talent-search-page").text() == "search") {
		$("#talent_title").autocomplete({
			source: '/talents/title_autosuggest'        			
			});
			
		$("#talent_city").autocomplete({
			source: '/talents/city_autosuggest'        			
			});
			
		$("#talent_province").autocomplete({
			source: '/talents/province_autosuggest'        			
			});

		$("#talent_field_of_study").autocomplete({
			source: '/talents/field_autosuggest'        			
			});
  };
	
  if($("#talent-search-page").text() == "index") {
		$("#talent_title").autocomplete({
			source: '/talents/title_autosuggest_2'        			
			});
			
		$("#talent_city").autocomplete({
			source: '/talents/city_autosuggest_2'        			
			});
			
		$("#talent_province").autocomplete({
			source: '/talents/province_autosuggest_2'        			
			});

		$("#talent_field_of_study").autocomplete({
			source: '/talents/field_autosuggest_2'        			
			});
  };		
	
	$('#user_names').select2({
		ajax: {
		  delay: 250,  
			url: '/users/autosuggest_users',
			dataType: 'json',
      processResults: function(results) {
        return {
					results: results			
					}
			}
		},
		placeholder: "Type name(s) here ...",
    allowClear: true,
    multiple: true,
		minimumInputLength: 2,
		templateResult: formatDropdown,
		templateSelection: formatSelection
  });
	
	$('#partnership_user_id').select2({
		ajax: {
		  delay: 250,  
			url: '/users/autosuggest_agents',
			dataType: 'json',
      processResults: function(results) {
        return {
					results: results			
					}
			}
		},
		placeholder: "Type name here ...",
    allowClear: true,
		minimumInputLength: 2,
		templateResult: formatDropdown2,
		templateSelection: formatSelection2
  });
	
	function formatDropdown (state) {
		if (!state.id) {
			return state.text;
		}
		var existing_member_ids = JSON.parse("[" + $("#existing-members").text() + "]");
		if (existing_member_ids.includes(state.id)) {
			return null;
		}
		var text_split = state.text.split(",");
		var name = text_split[0];
		var title = text_split[1];
		var email = text_split[2];
		var avatarurl = text_split[3]; 
		var $state = $(
		  '<span><img src='+avatarurl+' width="40" height="40"</span>' + ' ' +
			'<span>'+name+'</span>' + ', ' +
			'<span>'+title+'</span>'
		 );
		return $state;
	};
	
	function formatDropdown2 (state) {
		if (!state.id) {
			return state.text;
		}
		var existing_partner_ids = JSON.parse("[" + $("#existing-partners").text() + "]");
		if (existing_partner_ids.includes(state.id)) {
			return null;
		}
		var text_split = state.text.split(",");
		var name = text_split[0];
		var title = text_split[1];
		var email = text_split[2];
		var avatarurl = text_split[3]; 
		var $state = $(
		  '<span><img src='+avatarurl+' width="40" height="40"</span>' + ' ' +
			'<span>'+name+'</span>' + ', ' +
			'<span>'+title+'</span>'
		 );
		return $state;
	};
	
	function formatSelection (state) {
		if (!state.id) {
			return state.text;
		}
		var text_split = state.text.split(",");
		var name = text_split[0];
		var email = text_split[2];
		var $state = $(
			'<span>'+name+'</span>' + ' ' +
			'<span>('+email+')</span>'
		 );
		return $state;
	};
	
  function formatSelection2 (state) {
		if (!state.id) {
			return state.text;
		}
		var text_split = state.text.split(",");
		var name = text_split[0];
		var title = text_split[1];
		var $state = $(
			'<span>'+name+'</span>' + ' ' +
			'<span>('+title+')</span>'
		 );
		return $state;
	};
	
})