$(document).on('change', "#user_filter_name", onUserFilterNameChange) 
					 .on('click', "#user-filter-restore-button", onUserFilterRestoreButtonClick)
					 .on('click', "#user-filter-save-button", onUserFilterSaveButtonClick)
					 .on('click', "#user-save-as-filter-button", onUserFilterSaveAsButtonClick)
					 .on('click', "#user-filter-delete-button", onUserFilterDeleteButtonClick)
           .on('change', '#user_role', onUserRoleChange)
					 .on('turbolinks:load', scroll_bottom);

function onUserFilterNameChange() {
		var filter_id = $(this).val();
		var filter_type = $("#user_type").val();
		
		$.post('/user_filters/select_filter', {
				filter_id: filter_id,
				filter_type: filter_type
		}, null, 'script');
}

function onUserFilterRestoreButtonClick() {
		var filter_id = $("#user_filter_name").val();
		var filter_type = $("#user_type").val();
/* 	$.post('/user_filters/click_restore', { */
		$.post('/user_filters/select_filter', {
			 filter_id: filter_id,
			 filter_type: filter_type
		}, null, 'script');				
}

function onUserFilterSaveButtonClick() {
		var filter_id = $("#user_filter_name").val();
		var filter_params = getUserFilterValues();
		$.post('/user_filters/click_save', {
			 filter_id: filter_id,
			 filter_params: filter_params
		}, null, 'script');				
}

function onUserFilterSaveAsButtonClick() {
	  var filter_params = getUserFilterValues();
		$.post('/user_filters/popup_save_filter', {
			filter_params: filter_params
		}, null, 'script');			
}

function onUserFilterDeleteButtonClick() {
	  var filter_name = $("#user_filter_name").find('option:selected').text();
		var filter_type = $("#user_type").val();
		
		if(confirm("Are you sure to delete filter '" + filter_name + "' ?")){
				var filter_id = $("#user_filter_name").val();
				$.post('/user_filters/click_delete', {
					 id: filter_id,
					 filter_type: filter_type
				}, null, 'script');	
        return false;
     } 
		 else {
        //if click no.
        return false;
     }
}

function getUserFilterValues() {
	var filter_params = {
		filter_name: $("#user_filter_name").find('option:selected').text(),
	  role: $("#user_role").val(),
		full_name: $("#user_name").val(),
	  city: $("#user_city").val(),
	  province: $("#user_province").val(),
	  country: $("#user_country").val(),
	  company_name: $("#user_company_name").val(),
	  reputation_grade: $("#user_reputation_grade").val(),
	  referral_success_rate: $("#user_referral_success_rate").val(),
		specialized_in: $("#user_specialized_in").find('option:selected').text(),
		language: $("#user_language").val(),
	  candidate_offers: $("#user_candidate_offers").val(),
		posted_jobs: $("#user_posted_jobs").val(),
		opening_jobs: $("#user_posted_jobs").val()
	}
	
  return filter_params;
}

function onUserRoleChange() {
	var role_value = $("#user_role").val();
	
	if (role_value == 'agent') {
		$("#success_rate").removeClass("hide-field");
		$("#referral_success").removeClass("hide-field");
		$("#specialized_in").removeClass("hide-field");
	  $("#opening_jobs").addClass("hide-field");
		$("#posted_jobs").addClass("hide-field");
	  $("#user_opening_jobs").val("");
		$("#user_posted_jobs").val("");
	}
	else if (role_value == 'employer') {
		$("#success_rate").addClass("hide-field");
		$("#referral_success").addClass("hide-field");
		$("#specialized_in").addClass("hide-field");
	  $("#opening_jobs").removeClass("hide-field");
		$("#posted_jobs").removeClass("hide-field");
	  $("#user_success_rate").val("");
		$("#user_referrals").val("");
		$("#user_specialized_in").val("");
	}
	else {
		$("#success_rate").addClass("hide-field");
		$("#referral_success").addClass("hide-field");
		$("#specialized_in").addClass("hide-field");
	  $("#opening_jobs").addClass("hide-field");
		$("#posted_jobs").addClass("hide-field");
	  $("#user_success_rate").val("");
		$("#user_referrals").val("");
		$("#user_specialized_in").val("");
	  $("#user_opening_jobs").val("");
		$("#user_posted_jobs").val("");		
	}
}

function scroll_bottom() {
	if ($("#message-area").length)
	{
	  $('#message-area').scrollTop($('#message-area')[0].scrollHeight);
	}
}




