$(document).on('change', "#job_job_type", onJobTypeChange)
					 .on('change', "#job_filter_name", onJobFilterNameChange) 
					 .on('click', "#job-filter-restore-button", onJobFilterRestoreButtonClick)
					 .on('click', "#job-filter-save-button", onJobFilterSaveButtonClick)
					 .on('click', "#job-save-as-filter-button", onJobFilterSaveAsButtonClick)
					 .on('click', "#job-filter-delete-button", onJobFilterDeleteButtonClick)
					 .on('click', ".clear-button", onClearButtonClick)
					 .on('change', ".input-field", onInputFieldChange);

function onJobTypeChange() {
	var type_value = $("#job_job_type").val();
	
	if (type_value == 'Contract' || type_value == 'Temporary' || type_value == 'Internship') {
		$("#duration-b").removeClass("hide-field");
		$("#duration-label").removeClass("hide-field");
		$("#job_duration").removeClass("hide-field");
	}
	else {
		$("#duration-b").addClass("hide-field");
		$("#duration-label").addClass("hide-field");
		$("#job_duration").addClass("hide-field");	
	}
}

function onJobFilterNameChange() {
		var filter_id = $(this).val();
		
		$.post('/job_filters/select_filter', {
				filter_id: filter_id
		}, null, 'script');
}

function onJobFilterRestoreButtonClick() {
		var filter_id = $("#job_filter_name").val();
/* 	$.post('/job_filters/click_restore', { */
		$.post('/job_filters/select_filter', {
			 filter_id: filter_id
		}, null, 'script');				
}

function onJobFilterSaveButtonClick() {
		var filter_id = $("#job_filter_name").val();
		var filter_params = getJobFilterValues();
		$.post('/job_filters/click_save', {
			 filter_id: filter_id,
			 filter_params: filter_params
		}, null, 'script');				
}

function onJobFilterSaveAsButtonClick() {
	  var filter_params = getJobFilterValues();
		$.post('/job_filters/popup_save_filter', {
			filter_params: filter_params
		}, null, 'script');			
}

function onJobFilterDeleteButtonClick() {
	  var filter_name = $("#job_filter_name").find('option:selected').text();
		
		if(confirm("Are you sure to delete filter '" + filter_name + "' ?")){
				var filter_id = $("#job_filter_name").val();
				$.post('/job_filters/click_delete', {
					 id: filter_id
				}, null, 'script');	
        return false;
     } 
		 else {
        //if click no.
        return false;
     }
}

function getJobFilterValues() {
	var filter_params = {
		filter_name: $("#job_filter_name").find('option:selected').text(),
		category: $("#job_category").val(),
	  title: $("#job_title").val(),
	  city: $("#job_city").val(),
	  province: $("#job_province").val(),
	  country: $("#job_country").val(),
		remote: $("#job_remote").prop('checked'),
	  company_name: $("#job_company_name").val(),
	  job_type: $("#job_job_type").val(),
	  seniority_level: $("#job_seniority_level").val(),
	  posted_days: $("#job_posted_days").val()
	}
	
  return filter_params;
}

function onClearButtonClick() {
	$(this).closest(".input-line").find("input, select").val('');
	$(this).addClass("hide-field");
}

function onInputFieldChange() {
  if ($(this).val().length == 0) {
		$(this).closest(".input-line").find(".clear-button").addClass("hide-field"); 
  }
	else {
	  $(this).closest(".input-line").find(".clear-button").removeClass("hide-field");
	} 
		
}


/* function onJobRemoteChange() {
	if ($('#job_remote').is(":checked")) {
		$("#job-location").addClass("hide-field");
	}
	else {
		$("#job-location").removeClass("hide-field");
	}
} */





